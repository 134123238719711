import _ from 'lodash';
import { UserProfile } from '../types/app';
import db from './db';
import client from './client';

const profiles = db.db('users').collection<UserProfile>('profiles');

let lastFetchedProfile: UserProfile | null = null;
/** Should be internal to stitch dir */
export const fetchUserProfile = async (id: string, forceUpdate: boolean = false) => {
  if (!forceUpdate && lastFetchedProfile && lastFetchedProfile.authUserId === id) {
    return lastFetchedProfile;
  }
  const profile = await profiles.find({ authUserId: id }, { limit: 1 }).first();
  if (profile == null) {
    return null;
  }
  lastFetchedProfile = profile;
  return profile;
};

export async function updateUserProfile(
  authUserId: UserProfile['authUserId'],
  updates: Partial<UserProfile>
): Promise<UserProfile> {
  await profiles.updateOne({ authUserId }, { $set: updates });
  const profile = await fetchUserProfile(authUserId, true);
  if (profile == null) {
    throw new Error('Profile does not exist!');
  }
  return profile;
}

export async function getFriendProfiles(user: UserProfile): Promise<UserProfile[]> {
  const { friendIds } = user;
  if (_.isEmpty(friendIds) || friendIds == null) {
    return [];
  }
  const result = await profiles.find({ authUserId: { $in: friendIds } });
  return result.asArray();
}

export async function findUserByEmail(email: string): Promise<UserProfile | null> {
  return (await client.callFunction('findUserByEmail', [email])) || null;
}
