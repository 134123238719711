import React, { useState } from 'react';
import { css } from '@emotion/core';
import Layout from './Layout';
import { useLoginAction, useUserData } from '../components/Auth';
import { Redirect } from 'react-router';
import { FormWrapper, Form, InputGroup, SubmitButton, FormFooter } from '../components/FormPage';
import Spinner from '../components/Spinner';
import CenteredBody from '../components/CenteredBody';

export default function SignIn() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const { isLoggedIn, isPending, errorMessage } = useUserData();
  const login = useLoginAction();

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Layout title="Sign In">
      <CenteredBody>
        <FormWrapper errorMessage={errorMessage}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (!user || !password) {
                return;
              }

              login(user, password);
            }}
          >
            <InputGroup>
              <label htmlFor="sign-in-user">Email</label>
              <input id="sign-in-user" value={user} onChange={(e) => setUser(e.target.value)} />
            </InputGroup>
            <InputGroup>
              <label htmlFor="sign-in-password">Password</label>
              <input
                type="password"
                id="sign-in-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
            <FormFooter>{isPending ? <Spinner /> : <SubmitButton>Sign In</SubmitButton>}</FormFooter>
          </Form>
        </FormWrapper>
      </CenteredBody>
    </Layout>
  );
}
