import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

export function FormWrapper({
  errorMessage,
  children,
}: {
  errorMessage?: string | null;
  children: React.ReactNode;
}) {
  return (
    <div
      css={css`
        width: 400px;
        max-width: 100%;
      `}
    >
      {children}
      <div
        css={css`
          text-align: center;
          color: #a00;
          margin-top: 1em;
          height: 1em;
          line-height: 1em;
        `}
      >
        {errorMessage}
      </div>
    </div>
  );
}

export const Form = styled.form`
  background: #eee;
  border-radius: 4px;
  box-shadow: 0 0 20px #eee;
  overflow: hidden;
`;

export const StaticWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const InputGroup = styled.div`
  border-top: 1px solid #999;
  display: flex;

  &:first-of-type {
    border-top: 0;
  }

  label {
    padding: 1em;
    flex: 2;
    text-align: right;
    color: #333;
  }

  input:not([type='checkbox']),
  ${StaticWrapper} {
    background: #fff;
    border: 0;
    padding: 1em;
    flex: 3;
    color: #333;
    font-size: 1em;

    &[disabled] {
      color: #999;
      cursor: not-allowed;
    }
  }
`;

export const FormFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  background: #aaa;
  color: #333;
`;

export const SubmitButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    {...props}
    type="submit"
    css={css`
      height: 100%;
      width: 100%;
      appearance: none;
      border: 0;
      background: transparent;
      font-size: 1.5em;
      font-variant: small-caps;

      &:active {
        background: #333;
        color: #eee;
        box-shadow: inset 0 1px 10px #aaa;
      }

      &[disabled] {
        cursor: not-allowed;
        color: #666;
      }
    `}
  />
);

export const Checkbox = (props: React.InputHTMLAttributes<HTMLInputElement>) => (
  <StaticWrapper>
    <input {...props} type="checkbox" />
  </StaticWrapper>
);
