import React, { lazy, Fragment, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ErrorView from './components/ErrorView';
import { AuthProvider } from './components/Auth';
import Layout from './pages/Layout';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import SignOut from './pages/SignOut';
import CenteredBody from './components/CenteredBody';
import Spinner from './components/Spinner';

const NewGame = lazy(() => import('./pages/NewGame'));
const ConfirmSignup = lazy(() => import('./pages/ConfirmSignup'));
const EditUserProfile = lazy(() => import('./pages/EditUserProfile'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

function App() {
  return (
    <Fragment>
      <Helmet defaultTitle="Global Conflict" titleTemplate="Global Conflict | %s" />
      <AuthProvider>
        <Router>
          <Suspense
            fallback={
              <Layout>
                <CenteredBody>
                  <Spinner />
                </CenteredBody>
              </Layout>
            }
          >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/sign-up" component={SignUp} />
              <Route path="/sign-in" component={SignIn} />
              <Route path="/sign-out" component={SignOut} />
              <Route path="/new-game" component={NewGame} />
              <Route path="/confirm-signup" component={ConfirmSignup} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/user-profile" component={EditUserProfile} />
              <Route
                render={() => (
                  <Layout>
                    <ErrorView message="Not Found" />
                  </Layout>
                )}
              />
            </Switch>
          </Suspense>
        </Router>
      </AuthProvider>
    </Fragment>
  );
}

export default App;
