import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { ClassNames, css } from '@emotion/core';
import { useUserData } from '../components/Auth';
import CenteredBody from '../components/CenteredBody';
import Spinner from '../components/Spinner';

function AppLink({ children, to }: { children: React.ReactNode; to: string }) {
  return (
    <li
      css={css`
        margin: 0;
        padding: 0;
        border-bottom: 5px solid #aaa;
        background: linear-gradient(-20deg, #227, #006);
        clip-path: polygon(0.5em 0, 0 100%, 100% 100%, 100% 0);
      `}
    >
      <ClassNames>
        {({ css: classNameCss }) => (
          <NavLink
            css={css`
              display: block;
              color: inherit;
              text-decoration: none;
              padding: 1em;
              transition: all 0.4s;
              &:hover {
                background: #aaa;
                color: #333;
              }
            `}
            exact
            activeClassName={classNameCss`font-weight: bold;`}
            to={to}
          >
            {children}
          </NavLink>
        )}
      </ClassNames>
    </li>
  );
}

const Nav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 2em;
`;

export default function Home() {
  const { isLoggedIn, isPending } = useUserData();

  return (
    <div
      css={css`
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      `}
    >
      {isPending ? (
        <CenteredBody>
          <Spinner />
        </CenteredBody>
      ) : (
        <Nav>
          <AppLink to="/">Home</AppLink>
          {isLoggedIn ? (
            <Fragment>
              <AppLink to="/new-game">New Game</AppLink>
              <AppLink to="/user-profile">User Profile</AppLink>
              <AppLink to="/sign-out">Sign Out</AppLink>
            </Fragment>
          ) : (
            <Fragment>
              <AppLink to="/sign-in">Sign In</AppLink>
              <AppLink to="/sign-up">Sign Up</AppLink>
            </Fragment>
          )}
        </Nav>
      )}
    </div>
  );
}
