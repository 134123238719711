import React, { useState, useCallback } from 'react';
import { Redirect } from 'react-router';
import { css } from '@emotion/core';

import { useAsyncEffect } from '../hooks/useAsyncEffect';
import { signup } from '../stitch/auth';

import { useUserData } from '../components/Auth';
import { FormWrapper, Form, InputGroup, FormFooter, SubmitButton } from '../components/FormPage';
import Layout from './Layout';
import Spinner from '../components/Spinner';
import FullPageMessage from '../components/FullPageMessage';
import CenteredBody from '../components/CenteredBody';

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isLoggedIn } = useUserData();
  const [{ success, error, loading }, triggerSignup] = useAsyncEffect(
    useCallback(() => signup(email, password), [email, password]),
    false
  );

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  if (success) {
    return (
      <Layout title="Pending Email Confirmation">
        <CenteredBody>
          <FullPageMessage>Please check your email to finish creating your account.</FullPageMessage>
        </CenteredBody>
      </Layout>
    );
  }

  return (
    <Layout title="Sign Up">
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <FormWrapper errorMessage={error ? error.message : undefined}>
          <Form>
            <InputGroup>
              <label htmlFor="sign-up-email">Email</label>
              <input
                id="sign-up-email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </InputGroup>
            <InputGroup>
              <label htmlFor="sign-up-password">Password</label>
              <input
                id="sign-up-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </InputGroup>
            <FormFooter>
              {loading ? (
                <Spinner />
              ) : (
                <SubmitButton
                  onClick={(e) => {
                    e.preventDefault();
                    triggerSignup();
                  }}
                >
                  Sign Up
                </SubmitButton>
              )}
            </FormFooter>
          </Form>
        </FormWrapper>
      </div>
    </Layout>
  );
}
