import React from 'react';
import css from '@emotion/css';

export default function ErrorView({ message }: { message: string }) {
  return (
    <div>
      <h1
        css={css`
          margin-top: 0;
        `}
      >
        Error!
      </h1>
      <div>{message}</div>
    </div>
  );
}
