import styled from '@emotion/styled';

const FullPageMessage = styled.div`
  background: #eee;
  color: #333;
  padding: 2em;
  border-radius: 4px;
  font-size: 1.4em;
  font-weight: bold;
  max-width: 800px;
  text-align: center;
`;
export default FullPageMessage;
