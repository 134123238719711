export default async function retryAsync<T extends {}>(
  fn: () => Promise<T | null>,
  retries: number
): Promise<T> {
  for (let i = 0; i < retries; ++i) {
    const result = await fn();
    if (result != null) {
      return result;
    }
    await new Promise((res) => setTimeout(res, 1000));
  }
  return Promise.reject(new Error('Could not find requested entity'));
}
