import { UserPasswordAuthProviderClient, UserPasswordCredential } from 'mongodb-stitch-browser-sdk';

import { UserProfile } from '../types/app';
import client from './client';
import { fetchUserProfile } from './userProfile';
import retryAsync from '../util/retryAsync';

const authProviderClient = client.auth.getProviderClient(UserPasswordAuthProviderClient.factory);

export function confirmSignup(token: string, tokenId: string) {
  return authProviderClient.confirmUser(token, tokenId);
}

export function resetPassword(token: string, tokenId: string, newPassword: string) {
  return authProviderClient.resetPassword(token, tokenId, newPassword);
}

export function isLoggedIn() {
  return client.auth.isLoggedIn;
}

export function getUser(): Promise<UserProfile | null> {
  if (client.auth.user == null) {
    return Promise.resolve(null);
  }
  return fetchUserProfile(client.auth.user.id);
}

export function logout() {
  return client.auth.logout();
}

export async function login(user: string, password: string): Promise<UserProfile> {
  const stitchUser = await client.auth.loginWithCredential(new UserPasswordCredential(user, password));
  const profile = await retryAsync(() => fetchUserProfile(stitchUser.id), 5);

  return profile;
}

export function signup(email: string, password: string) {
  return authProviderClient.registerWithEmail(email, password);
}
