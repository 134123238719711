import React from 'react';
import { keyframes, css } from '@emotion/core';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export default function Spinner({ size = '2em' }: { size?: string }) {
  return (
    <div
      css={css`
        border-radius: 50%;
        border: 2px solid white;
        border-top: 2px solid transparent;
        width: ${size};
        height: ${size};
        position: relative;
        animation: 1s linear infinite ${spin};
      `}
    />
  );
}
