import { useEffect, useState, useReducer, useDebugValue } from 'react';

export interface AsyncState<T> {
  loading: boolean;
  error: Error | null;
  success: boolean;
  value: T | null;
}

interface StartLoadingAction {
  type: 'startLoading';
}

interface SuccessAction<T> {
  type: 'success';
  payload: T;
}

interface ErrorAction {
  type: 'error';
  payload: Error;
}

interface ResetAction {
  type: 'reset';
}

type Action<T> = StartLoadingAction | SuccessAction<T> | ErrorAction | ResetAction;

const initialState: AsyncState<any> = {
  loading: false,
  error: null,
  success: false,
  value: null,
};

function asyncEffectReducer<T>(prevState: AsyncState<T>, action: Action<T>): AsyncState<T> {
  switch (action.type) {
    case 'startLoading': {
      return {
        ...initialState,
        loading: true,
      };
    }

    case 'success': {
      return {
        ...initialState,
        success: true,
        value: action.payload,
      };
    }

    case 'error': {
      return {
        ...initialState,
        error: action.payload,
      };
    }

    case 'reset': {
      return initialState;
    }
  }
}

/**
 * Takes a function that returns a promise, and an optional flag that indicates if
 * the function should be called immediately (defaults to true).
 * Returns a tuple where the first item is a { loading, error, success, value } object,
 * the second is a function to trigger the async function (for use with non-immediate usecase),
 * and the third is a function that resets state.
 */
export function useAsyncEffect<T>(
  fn: () => Promise<T>,
  immediate: boolean = true
): [AsyncState<T>, () => void, () => void] {
  const [triggered, setTriggered] = useState(immediate);
  const [state, dispatch] = useReducer<AsyncState<T>, Action<T>>(asyncEffectReducer, initialState);

  useDebugValue(
    state.success
      ? state.value == null
        ? 'Loaded!'
        : state.value
      : state.loading
      ? 'Loading...'
      : 'Not Loaded'
  );

  useEffect(
    () => {
      if (!immediate && !triggered) {
        return;
      }

      let cancelled = false;
      dispatch({ type: 'startLoading' });

      fn().then(
        (val) => {
          if (cancelled) {
            return;
          }

          dispatch({ type: 'success', payload: val });
        },
        (error: Error) => {
          if (cancelled) {
            return;
          }

          dispatch({ type: 'error', payload: error });
        }
      );

      // If a different function was given, just flag the current request as cancelled.
      return () => {
        cancelled = true;
        setTriggered(immediate);
      };
    },
    [fn, triggered || immediate]
  );

  return [
    state,
    () => setTriggered(true),
    () => {
      setTriggered(immediate);
      dispatch({ type: 'reset' });
    },
  ];
}
