import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

export default function DelayedRedirect({ delay, to }: { delay: number; to: string }) {
  const [delayFinished, setDelayFinished] = useState(false);

  useEffect(() => {
    const id = window.setTimeout(() => setDelayFinished(true), delay);
    return () => window.clearTimeout(id);
  });

  if (delayFinished) {
    return <Redirect to={to} />;
  }

  return null;
}
