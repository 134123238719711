import React, { useEffect } from 'react';
import { css } from '@emotion/core';

import { useLogoutAction, useUserData } from '../components/Auth';
import DelayedRedirect from '../components/DelayedRedirect';
import FullPageMessage from '../components/FullPageMessage';
import Layout from './Layout';
import CenteredBody from '../components/CenteredBody';

export default function SignOut() {
  const { isPending, isLoggedIn } = useUserData();
  const logout = useLogoutAction();
  // const [state] = useAsyncEffect(useLogoutAction());

  useEffect(() => {
    logout();
  }, []);

  return (
    <Layout>
      <CenteredBody>
        <FullPageMessage>
          {isPending && 'Signing out...'}
          {!isLoggedIn && 'Successfully signed out'}
          {!isLoggedIn && <DelayedRedirect delay={3000} to="/" />}
        </FullPageMessage>
      </CenteredBody>
    </Layout>
  );
}
