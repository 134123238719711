import React, { Fragment } from 'react';
import { css, ClassNames } from '@emotion/core';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import Helmet from 'react-helmet';

import { useUserData } from '../components/Auth';

const headerHeight = '3rem';

function NavHeaderLink({ children, to }: { children: React.ReactNode; to: string }) {
  return (
    <li
      css={css`
        display: inline-block;
        margin: 0;
        padding: 0;
        height: ${headerHeight};
        line-height: ${headerHeight};
        background: linear-gradient(-20deg, #227, #006);
        clip-path: polygon(0.5em 0, 0 100%, 100% 100%, 100% 0);
        margin-left: calc(-0.5em - 1px);

        &:first-of-type {
          margin-left: 0;
        }
      `}
    >
      <ClassNames>
        {({ css: classNameCss }) => (
          <NavLink
            css={css`
              display: block;
              color: inherit;
              text-decoration: none;
              padding: 0 1em;
              transition: all 0.4s;
              &:hover {
                background: #aaa;
                color: #333;
              }
            `}
            exact
            activeClassName={classNameCss`font-weight: bold; opacity: 0.7;`}
            to={to}
          >
            {children}
          </NavLink>
        )}
      </ClassNames>
    </li>
  );
}

const NavHeader = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Body = styled.div`
  flex: 1;
  padding: 1rem;
`;

export interface LayoutProps {
  children: React.ReactNode;
  title?: string;
}

export default function Layout({ children, title }: LayoutProps) {
  const { isLoggedIn, isPending } = useUserData();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
      `}
    >
      <Helmet title={title} />
      <header
        css={css`
          display: flex;
          background: #ccc;
        `}
      >
        <h1
          css={css`
            flex: 1;
            padding: 0 1rem;
            margin: 0;
            font-size: 1.2em;
            height: ${headerHeight};
            line-height: ${headerHeight};
            color: #333;
          `}
        >
          {title}
        </h1>
        <NavHeader>
          <NavHeaderLink to="/">Home</NavHeaderLink>
          {!isPending &&
            (isLoggedIn ? (
              <Fragment>
                <NavHeaderLink to="/new-game">New Game</NavHeaderLink>
                <NavHeaderLink to="/user-profile">User Profile</NavHeaderLink>
                <NavHeaderLink to="/sign-out">Sign Out</NavHeaderLink>
              </Fragment>
            ) : (
              <Fragment>
                <NavHeaderLink to="/sign-in">Sign In</NavHeaderLink>
                <NavHeaderLink to="/sign-up">Sign Up</NavHeaderLink>
              </Fragment>
            ))}
        </NavHeader>
      </header>
      <Body>{children}</Body>
    </div>
  );
}
