import React, { Fragment, createContext, useContext, useReducer, useEffect } from 'react';
import { StitchUser } from 'mongodb-stitch-browser-sdk';

import { isLoggedIn, getUser, login, logout } from '../stitch/auth';
import { updateUserProfile } from '../stitch/userProfile';
import { UserProfile } from '../types/app';
import Layout, { LayoutProps } from '../pages/Layout';
import { Redirect } from 'react-router';
import CenteredBody from './CenteredBody';
import Spinner from './Spinner';

interface BaseState {
  readonly isPending: boolean;
  readonly errorMessage?: string;
}

interface LoggedInState extends BaseState {
  readonly isLoggedIn: true;
  readonly user: Readonly<UserProfile>;
}

interface LoggedOutState extends BaseState {
  readonly isLoggedIn: false;
  readonly user: null;
}

type State = LoggedInState | LoggedOutState;

interface LogInStartAction {
  type: 'login_start';
}

interface LogInSuccessAction {
  type: 'login_success';
  payload: UserProfile;
}

interface LogInErrorAction {
  type: 'login_error';
  payload: Error;
}

interface FetchProfileStartAction {
  type: 'fetch_profile_start';
}

interface FetchProfileSuccessAction {
  type: 'fetch_profile_success';
  payload: UserProfile;
}

interface UpdateProfile {
  type: 'update_profile';
  payload: UserProfile;
}

interface LogOutStartAction {
  type: 'logout_start';
}

interface LogOutSuccessAction {
  type: 'logout_success';
}

type Action =
  | LogInStartAction
  | LogInSuccessAction
  | LogInErrorAction
  | FetchProfileStartAction
  | FetchProfileSuccessAction
  | UpdateProfile
  | LogOutStartAction
  | LogOutSuccessAction;

interface AuthContextI {
  readonly state: State;
  readonly login: (user: string, password: string) => Promise<void>;
  readonly logout: () => Promise<void>;
  readonly updateProfile: (updates: Partial<UserProfile>) => Promise<void>;
}

const getInitialState = (): State => ({
  isLoggedIn: false,
  isPending: isLoggedIn(),
  user: null,
});

const illegalAction = () =>
  Promise.reject(new Error('Must not try to access AuthContext without a Provider'));

const fallbackAuthContext: AuthContextI = {
  state: getInitialState(),
  login: illegalAction,
  logout: illegalAction,
  updateProfile: illegalAction,
};

const AuthContext = createContext<AuthContextI>(fallbackAuthContext);
AuthContext.displayName = 'Auth';

function authReducer(prevState: State, action: Action): State {
  switch (action.type) {
    case 'login_start': {
      return {
        isLoggedIn: false,
        isPending: true,
        user: null,
      };
    }

    case 'fetch_profile_success':
    case 'login_success': {
      return {
        isLoggedIn: true,
        isPending: false,
        user: action.payload,
      };
    }

    case 'fetch_profile_start': {
      return {
        ...prevState,
        isPending: true,
      };
    }

    case 'update_profile': {
      if (!prevState.isLoggedIn) {
        return prevState;
      }
      return {
        ...prevState,
        user: action.payload,
      };
    }

    case 'login_error': {
      return {
        isLoggedIn: false,
        isPending: false,
        user: null,
        errorMessage: action.payload.message,
      };
    }

    case 'logout_start': {
      return {
        ...prevState,
        isPending: true,
      };
    }

    case 'logout_success': {
      return {
        isLoggedIn: false,
        isPending: false,
        user: null,
      };
    }
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(authReducer, getInitialState());

  // Load user profile for initial page load
  useEffect(() => {
    if (!isLoggedIn()) {
      // Stitch says we're not logged in, so there's nothing to fetch.
      return;
    }

    dispatch({ type: 'fetch_profile_start' });
    getUser().then((profile) => {
      if (profile == null) {
        // Failed to fetch the user profile. Log out to keep things in a consistent state.
        logout().then(() => {
          dispatch({ type: 'logout_success' });
        });
        return;
      }
      dispatch({ type: 'fetch_profile_success', payload: profile });
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        login: async (user, password) => {
          dispatch({ type: 'login_start' });
          try {
            const userResp = await login(user, password);
            dispatch({ type: 'login_success', payload: userResp });
          } catch (e) {
            dispatch({ type: 'login_error', payload: e });
          }
        },
        updateProfile: async (updates: Partial<UserProfile>) => {
          if (state.user == null) {
            throw new Error('Not logged in');
          }
          const profile = await updateUserProfile(state.user.authUserId, updates);
          dispatch({ type: 'update_profile', payload: profile });
        },
        logout: async () => {
          if (!state.isLoggedIn) {
            return;
          }

          dispatch({ type: 'logout_start' });
          await logout();
          dispatch({ type: 'logout_success' });
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useUserData() {
  return useContext(AuthContext).state;
}

export function useLoginAction() {
  return useContext(AuthContext).login;
}

export function useLogoutAction() {
  return useContext(AuthContext).logout;
}

export function useUpdateProfileAction() {
  return useContext(AuthContext).updateProfile;
}

export function useRequireLogin(
  layoutProps: Pick<LayoutProps, Exclude<keyof LayoutProps, 'children'>>
): { user: Readonly<UserProfile>; component: null } | { user: null; component: React.ReactElement<any> } {
  const { state } = useContext(AuthContext);

  if (!state.isLoggedIn && !state.isPending) {
    return {
      user: null,
      component: <Redirect to="/sign-in" />,
    };
  }

  if (!state.isLoggedIn) {
    return {
      user: null,
      component: (
        <Layout {...layoutProps}>
          <CenteredBody>
            {/* TODO: When Suspense for data fetching comes out, do that instead */}
            <Spinner />
          </CenteredBody>
        </Layout>
      ),
    };
  }

  return { user: state.user, component: null };
}
